var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "star-score-container" },
    _vm._l(5, function (ele, index) {
      return _c(
        "div",
        { key: index + "teacher-star", staticClass: "img-item" },
        [
          _vm.score <= index
            ? _c("img", {
                attrs: {
                  src: require("@/assets/img/07-Stars/star-empty.svg"),
                  alt: "",
                },
              })
            : _vm._e(),
          _vm.scoreFormInfo.half === 1 && index === _vm.scoreFormInfo.index
            ? _c("img", {
                attrs: {
                  src: require("@/assets/img/07-Stars/star-half.svg"),
                  alt: "",
                },
              })
            : _vm._e(),
          _vm.scoreFormInfo.index > index
            ? _c("img", {
                attrs: {
                  src: require("@/assets/img/07-Stars/star-full.svg"),
                  alt: "",
                },
              })
            : _vm._e(),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }