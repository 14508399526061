<template>
  <div class="star-score-container">
    <div
      class="img-item"
      v-for="(ele, index) in 5"
      :key="index + 'teacher-star'"
    >
      <img
        src="@/assets/img/07-Stars/star-empty.svg"
        alt=""
        v-if="score <= index"
      />
      <img
        src="@/assets/img/07-Stars/star-half.svg"
        alt=""
        v-if="scoreFormInfo.half === 1 && index === scoreFormInfo.index"
      />
      <img
        src="@/assets/img/07-Stars/star-full.svg"
        alt=""
        v-if="scoreFormInfo.index > index"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "startScore",
  props: {
    score: {
      type: String,
      require: true,
    },
  },
  computed: {
    scoreFormInfo() {
      const interger = parseInt(this.score);
      const half = parseInt(this.score.split(".")[1]) === 5 ? 1 : 0;
      // console.log(interger, half);
      return {
        index: interger,
        half,
      };
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.star-score-container {
  width: 100%;
  height: 100%;
  display: flex;
  .img-item {
    img {
      max-width: 20px;
    }
  }
}
</style>
